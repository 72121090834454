import 'jspdf-autotable';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AiOutlinePrinter, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './DeliveryChalanLookup.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { myaction } from '../../redux/Mystore';

const DeliverychalanLookup = ({ chalanId }) => {
    let chalanName = '';
    let navigateurl = '';
    if(chalanId === 1){
        chalanName = 'Branch Transfer';
        navigateurl = "/installdeliverychalan-branchtransfer";
    }
    if(chalanId === 2){
        chalanName = 'Job Based';
        navigateurl = "/installdeliverychalan-jobbased";
    }

    const mydispatch = useDispatch()
    const userid = useSelector(function (data) {
        return data.userid;
    })
    console.log(userid);
    const coyid = useSelector(function (data) {
        return data.coyid;
    })
    const gridRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);

    const [search, setSearch] = useState('');

    const fetchdata = async () => {
        try {
            const data = {
                coyid: coyid,
                userid: userid
            }
            console.log(data);
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_readall`, data);
            console.log(response)
            const modifiedData = response.data.map(item => ({
                ...item,
                isSelected: false 
            }));
            setinitialData(modifiedData);
            setcurdata(modifiedData);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, [coyid, userid]);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        setSelectedIndex(0);
        if (value === "") {
            setcurdata(initialData);
            return;
        }
        const filtered = initialData.filter(item => item.deliveryat.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);

    };
    const [refNoSearch, setRefNoSearch] = useState("");
    const handleRefNoSearchChange = (event) => {
        const value = event.target.value;
        setRefNoSearch(value);
        if (value === "") {
            setcurdata(initialData);
            return;
        }
        const filtered = initialData.filter(item => item.docuno === parseInt(value));
        setcurdata(filtered);

    };

    const handleDelete = (data) => {
        Swal.fire({
            title: `Are you sure to delete Ref.No ${data.docuno} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            docuno: data.docuno,
            coyid: coyid,
            userid: userid
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_delete`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const handleEdit = (data) => {
        mydispatch(myaction.setdeliverychalanmode(["Edit", data.docuno]));
        navigate(navigateurl)
    }

    const handlePrintSelected = async () => {
        if (selectedList.length === 0) {
            alert("No. Item(s) Selected to Preview");
            return;
        }
        const xdata = { docuno: selectedList }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');
        setSelectedList([]);
        gridRef.current.api.deselectAll();
        gridRef.current.api.focusCell({ rowIndex: 0, colId: 'refno' });
    }


    const handlePrint = async (data) => {
        const xdata = { docuno: [data.docuno] }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');

    }

    const handleAddNew = () => {
        mydispatch(myaction.setdeliverychalanmode(["New", 0]));
        navigate(navigateurl);
    }

    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const [selectedList, setSelectedList] = useState([]);

    const handleCheckboxChange = (selectedId) => {
        setSelectedList(prevSelectedList => {
            if (prevSelectedList.includes(selectedId)) {
                return prevSelectedList.filter(id => id !== selectedId);
            } else {
                return [...prevSelectedList, selectedId];
            }
        });
    };

    const [columnDefs] = useState([
        {
            headerClass: 'custom-header',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerName: '',
            field: 'select',
            width: 50,
            filter: false,
            pinned: "left",
        },
        { headerName: 'Ref.No', field: 'docuno', headerClass: 'custom-header', sortable: false, filter: false, flex: 0.3, resizable: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Date', field: 'docudate', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.5, cellStyle: { overflow: 'hidden', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Delivery At', field: 'deliveryat', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 1.5, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },       
        { headerName: 'Delivery Place', field: 'deliveryplace', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Delivery GSTIN', field: 'deliverygstin', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Despatch From', field: 'despatchfrom', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 1.5, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Goods Value', field: 'goodsvalue', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'D', field: 'discount', headerClass: 'custom-header', sortable: false, filter: false, resizable: true, flex: 0.28, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'Pvt. Mark', field: 'pvtmark', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'Price List', field: 'pricelistname', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },

        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: (params) => (
                <div className="col-action-div action-cell">
                    <button onClick={() => handleEdit(params.data)}>Edit</button>
                    <button onClick={() => handleDelete(params.data)}>Delete</button>
                    <button onClick={() => handlePrint(params.data)}>Print</button>
                </div>
            ),
            headerClass: 'custom-header',
            flex: 0.6,
            cellStyle: { whiteSpace: "nowrap" }
        }
    ]);

    const gridOptions = {
        rowSelection: "multiple",
        onRowDoubleClicked: (event) => handleEdit(event.data),
    };
    const [arrowKeyUsed, setArrowKeyUsed] = useState(false); // Track arrow key usage
    const [spaceKeyUsed, setspaceKeyUsed] = useState(false);

    const handleKeyDown = (event) => {
        if (!curdata.length) return; // Exit if there's no data

        if (event.key === 'ArrowDown') {
            setspaceKeyUsed(false);
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.min(prevIndex + 1, curdata.length - 1);
                return newIndex;
            });
            setArrowKeyUsed(true);

        } else if (event.key === 'ArrowUp') {
            setspaceKeyUsed(false);
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.max(prevIndex - 1, 0);
                return newIndex;
            });
            setArrowKeyUsed(true);

        } else if (event.key === 'Enter') {
            setspaceKeyUsed(false);
            handleEdit(curdata[selectedIndex]);
            setArrowKeyUsed(false);

        }
        else if (event.keyCode === 32) {
            setspaceKeyUsed(true);
            setArrowKeyUsed(false);
        }
    };

    const handleRowClick = (event) => {
        const index = event.rowIndex;
        setSelectedIndex(index);
        setArrowKeyUsed(false);
        setspaceKeyUsed(false);
    };

    useEffect(() => {
        const handleKeyDownGlobal = (event) => {
            handleKeyDown(event);
        };

        window.addEventListener('keydown', handleKeyDownGlobal);
        return () => {
            window.removeEventListener('keydown', handleKeyDownGlobal);
        };
    }, [curdata, selectedIndex, arrowKeyUsed]);

    const getRowClass = (params) => {
        if (spaceKeyUsed) {
            gridRef.current.api.getDisplayedRowAtIndex(selectedIndex).setSelected(true);
        }
        return params.node.rowIndex === selectedIndex ? 'highlighted-row' : '';
    };

    const [previousSelectedRows, setPreviousSelectedRows] = useState([]);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        const selectedDocuno = selectedRows.map(row => row.docuno);

        const newSelections = selectedDocuno.filter(docuno => !previousSelectedRows.includes(docuno));
        const deselections = previousSelectedRows.filter(docuno => !selectedDocuno.includes(docuno));

        setSelectedList(prevSelectedList => {
            let updatedList = [...prevSelectedList];

            // Add new selections to the list
            newSelections.forEach(docuno => {
                updatedList.push(docuno);
            });

            // Remove deselections from the list
            deselections.forEach(docuno => {
                updatedList = updatedList.filter(id => id !== docuno);
            });
            return updatedList;
        });

        // Update previous selected rows state for comparison on next call
        setPreviousSelectedRows(selectedDocuno);
    }

    const handleJsonDownload = async () => {
        if (selectedList.length === 0) {
            alert("No. Item(s) Selected to Generate Json");
            return;
        }
        try {
            const xdata = { docuno: selectedList }
            const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_readjson`
            const response = await axios.post(url, xdata);
            const jsonString = JSON.stringify(response.data, null, 1);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `deliverychalan.json`; 
            link.click();

            // Cleanup: revoke the object URL after the download
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    return (
        <div id="frmlookupdeliverychalan" className='frmlookupdeliverychalan-divmain'>
            <div className="table-container">
                <h3>Deliverychalan ({chalanName})</h3>
                
                <div className='searchtext'>
                    <span className='col-selectmark'> </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        id='refno'
                        type="text"
                        name="refno"
                        placeholder="Search"
                        value={refNoSearch}
                        onChange={handleRefNoSearchChange}
                        className="search-input col-docuno"
                        autoComplete='off'
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="text"
                        placeholder="Search Delivery at"
                        name="customer"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input col-actname"
                        autoComplete='off'
                    />
                    <span className='col-place'> </span>
                    <span className='col-gstin'> </span>
                    <span className='col-discount'> </span>
                    <span className='col-pvtmark' > </span>
                    <span className='col-pricelistno'> </span>

                    <div className="mybtncontainer col-action">
                        <button className='mybtn' onClick={handleJsonDownload}>JSON</button>
                        <button className="mybtn" onClick={handlePrintSelected}><AiOutlinePrinter className="btn-icon" />Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className="btn-icon" />Addnew</button>
                    </div>

                </div>
                <div className='lookupTable'>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={curdata}
                            columnDefs={columnDefs}
                            // defaultColDef={defaultColDef}
                            gridOptions={gridOptions}
                            rowSelection="multiple"
                            rowHeight={25}
                            onRowClicked={handleRowClick} // Handle row click
                            getRowClass={getRowClass}
                            domLayout="normal"
                            onSelectionChanged={onSelectionChanged}
                        // onFilterChanged={onFilterChanged}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliverychalanLookup;
