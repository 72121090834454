import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from "axios";
import './UsersList.css'
import { useEffect, useRef, useState } from 'react';
import { FaSync } from 'react-icons/fa';

const UsersList = () => {

    const gridRef = useRef();
    const [usersData, setUsersData] = useState();

    useEffect(() =>{
        fetchAllUsers();
    },[])

    const fetchAllUsers = async () => {
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readalluser`);
        console.log(response);
        const data = addNum(response.data)
        setUsersData(data);
    };

    const [columnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.3,
        },
        { headerName: 'Name', headerClass: 'custom-header', field: 'username',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8 },
        { headerName: 'Password', headerClass: 'custom-header', field: 'password',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.7 },
        { headerName: 'Email', headerClass: 'custom-header', field: 'email', editable: false,filter: 'true', cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1 },
        { headerName: 'Mobile', headerClass: 'custom-header', field: 'mobile', editable: false,filter: 'true', cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        { headerName: 'R9', headerClass: 'custom-header', field: 'licensed',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        { headerName: 'R1', headerClass: 'custom-header', field: 'licensed_gstr1hsn',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        { headerName: 'R2B', headerClass: 'custom-header', field: 'licensed_gstr2b',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.4 },
        { headerName: 'System Serial', headerClass: 'custom-header', field: 'serial',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.9 },
        { headerName: 'DOC', headerClass: 'custom-header', field: 'doc',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px', fontSize:"12px"}, flex: 0.65},
        { headerName: 'DOM', headerClass: 'custom-header', field: 'dom',filter: 'true', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px',fontSize:"12px" }, flex: 0.65 },
    ]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };

    return (

        <div className='userslist-gridpanel'>
        <div className="userslist-aggrid ">
            <div className='userslist-refresh'>
                <h4 style={{marginLeft:"5px"}}>Users-List</h4>
                <FaSync onClick={fetchAllUsers} style={{fontSize:'25px', backgroundColor:"gray", color:"white", padding:"5px"}}/>
            </div>
            <div className="aag-theme-alpine" style={{ width: "100%", height: "95%" }}>
                <AgGridReact
                    ref={gridRef}
                    rowHeight={25}
                    rowData={usersData}
                    columnDefs={columnDefs}
                    domLayout="normal"
                    defaultColDef={{ resizable: true, flex: 1 }}
                />

            </div>
        </div>
    </div>
    )
}
export default UsersList;