import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./ProductMasterList.css";
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';

const ProductMasterList = ({ closeModal, onRowDoubleClick, selecteditems }) => {
  const [initialdata, setinitialdata] = useState(selecteditems);
  const [curdata, setcurdata] = useState([]);

  const gridRef = useRef(null);
  const searchNameRef = useRef(null);
  const userid = useSelector(function (data) {
    return data.userid;
  })
  const coyid = useSelector(function (data) {
    return data.coyid;
  })

  const fetchdata = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readall`);
      setSelectedRow(response.data[0]);
      setcurdata(response.data);
      if (initialdata.pdtcode === 0) {
        setinitialdata(response.data[0])
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    searchNameRef.current.focus();
  }, []);

  const columnDefs = [
    { headerName: 'Product Name', field: 'pdtname', sortable: true, filter: 'agTextColumnFilter', width: 300, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
    { headerName: 'UOFM.', field: 'uofm', sortable: true, filter: true, width: 70, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
    { headerName: 'Case Content', field: 'casecontent', sortable: true, filter: true, width: 70, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
    { headerName: 'Serial No.', field: 'pdtserial', sortable: true, filter: true, width: 70, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
    { headerName: 'Product Code', field: 'pdtcode', hide: true, width: 0 },
  ];

  useEffect(() => {
    if (initialdata && gridRef.current && gridRef.current.api) {
      const index = curdata.findIndex(item => item.pdtcode === initialdata.pdtcode); // Adjust based on your data structure
      if (index >= 0) {
        gridRef.current.api.forEachNode((node) => {
          if (node.data.pdtcode === initialdata.pdtcode) {
            node.setSelected(true); // Select the item
            gridRef.current.api.ensureIndexVisible(index); // Scroll to the selected row
          }
        });
      }
      else {
        const firstNode = gridRef.current.api.getDisplayedRowAtIndex(0);  // Get the first row node
        if (firstNode) {
          firstNode.setSelected(true); // Select the first row
          gridRef.current.api.ensureIndexVisible(0); // Scroll to the first row
        }
      }
    }
  }, [initialdata, curdata]);

  const [api, setApi] = useState(null);

  const onGridReady = (params) => {
    setApi(params.api);  // Set API to state
  };

  const handleFilterTextChange = (filterValue) => {
    if (api) {
      const filterModel = {
        pdtname: {
          filterType: "text",
          type: "contains",
          filter: filterValue.toLowerCase(),
        },
      };
      gridRef.current.api.setFilterModel(filterModel);
      gridRef.current.api.onFilterChanged();
      setTimeout(() => {
        const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
        if (displayedRowCount > 0) {
          const firstVisibleNode = gridRef.current.api.getDisplayedRowAtIndex(0);
          if (firstVisibleNode) {
            firstVisibleNode.setSelected(true);
            gridRef.current.api.ensureIndexVisible(firstVisibleNode.rowIndex);
          }
        }
      }, 1)
    }
  };


  const getRowClass = (params) => {
    return params.node.isSelected() ? 'aggrid-selecteditem' : '';
  };

  const [SelectedRow, setSelectedRow] = useState(null);

  const onSelectionChanged = useCallback((event) => {
    const selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length > 0) {
      setSelectedRow(selectedNodes[0].data);
    } else {
      setSelectedRow(null);
    }
  }, []);

  const onRowSelected = useCallback((event) => {
    if (event.node.selected) {
      setSelectedRow(event.data);
      console.log(onselect);
    } else {
      setSelectedRow(null);
    }
  }, []);

  const onClickSelect = () => {
    onRowDoubleClick(SelectedRow);
  }

  const onRowDoubleClicked = (event) => {
    setSelectedRow(event.data);
    onRowDoubleClick(event.data);
  }

  const onCellFocused = useCallback((event) => {
    const { rowIndex } = event;
    if (rowIndex !== undefined) {
      const rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
      if (rowNode) {
        rowNode.setSelected(true);
      }
    }
  }, []);

  const onCellKeyDown = useCallback((event) => {
    const { node, event: nativeEvent } = event;
    const key = nativeEvent.key || nativeEvent.code;
    switch (key) {
      case 'Enter':
        if (node) {
          setSelectedRow(node.data); // Update state with selected row data
          onRowDoubleClick(node.data);
        }
        break;
      default:
        break;
    }
  }, []);

  const [currentRowIndex, setCurrentRowIndex] = useState(-1);

  const handledivKeyDown = (event) => {
    if (event.key === 'Enter' && gridRef.current) {
      const selectedNode = gridRef.current.api.getSelectedNodes()[0];
      if (selectedNode) {
        event.stopPropagation();
        setSelectedRow(selectedNode.data); // Update state with selected row data
        onRowDoubleClick(selectedNode.data);
      }
    }
  }

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (SelectedRow) { onRowDoubleClick(SelectedRow); }
      return;
    }
    const totalRows = gridRef.current.api.getDisplayedRowCount();;
    if (event.key === 'ArrowDown') {
      let newRowIndex = currentRowIndex < totalRows - 1 ? currentRowIndex + 1 : currentRowIndex;
      setCurrentRowIndex(newRowIndex);
      gridRef.current.api.ensureIndexVisible(newRowIndex);
      gridRef.current.api.getDisplayedRowAtIndex(newRowIndex).setSelected(true);
    } else if (event.key === 'ArrowUp') {
      let newRowIndex = currentRowIndex > 0 ? currentRowIndex - 1 : 0;
      setCurrentRowIndex(newRowIndex);
      gridRef.current.api.ensureIndexVisible(newRowIndex);
      gridRef.current.api.getDisplayedRowAtIndex(newRowIndex).setSelected(true);
    }
  };

  return (

    <div className="frmSelect-modal">
      <div className="frmSelect-modal-content">

        <div className="frmSelect-mainpage">
          <div div className="frmSelect-main">
            <div style={{ margin: 1, height: "98%", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div
                className="ag-theme-alpine custom-header"
                style={{ margin: 1, height: '98%', width: "100%" }}
                tabIndex={0}
                onKeyDown={handledivKeyDown} // Listen for key events
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={curdata}
                  columnDefs={columnDefs}
                  rowHeight={20}
                  onRowSelected={onRowSelected}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onCellFocused={onCellFocused}
                  onCellKeyDown={onCellKeyDown}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="single"
                  enableRangeSelection={false}
                  // getRowClass={getRowClass}
                  onGridReady={onGridReady}
                  enableFilter={true}
                />
              </div>
            </div>

            <div className="frmSelect-bottom">
              <div className="search-container">
                <input
                  type="text"
                  name="customername"
                  ref={searchNameRef}
                  autoComplete="off"
                  className='search-input'
                  onChange={(e) => handleFilterTextChange(e.target.value)}
                  onKeyDown={handleOnKeyDown}
                />
                <FaSearch className="search-icon" />
              </div>
              <div className="btngroup">
                <button className="btn" onClick={closeModal}>Close</button>
                <button className="btn" onClick={onClickSelect}>Select</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ProductMasterList;
