import "./ContactUs.css";
import QRCodeImg from "../../images/QRCode.jpg"

const Contactform = () => {

    return (
        <div className="contactform-divmain">
            <div className="contactform-container">
            </div>
            <div className="bankaccount-info">
                <div className="contact-inf">
                    <h3 style={{color:"rgb(148, 110, 28)", textDecoration: "underline"}}>Contact Details</h3>
                    <h2 style={{color:"rgb(248, 0, 128)"}}>ANBU COMPUTERS</h2>
                    <h6>#12, GUZHANTHAIVEL COMPLEX</h6>
                    <h6>OPP. ANNAI HOSPITAL</h6>
                    <h4 style={{color:"rgb(248, 0, 128)"}}>SIVAKASI - 626123</h4>
                    <h6>VIRUDHUNAGAR DISTRICT</h6>
                    <h6>TAMILNADU</h6>
                    <h4 style={{color:"rgb(248, 0, 128)"}}>EMAIL: anbucomputers1998@gmail.com</h4>
                    <h4 style={{color:"rgb(248, 0, 128)"}}>MOBILE: 9442774929</h4>
                </div>
                <div className="bank-inf">
                    <h3 style={{textDecoration: "underline"}}>Bank Details:</h3>
                    <label>Account Name : <label style={{color:"rgb(148, 10, 228)"}}>Anbu Computers</label></label>
                    <label>Current Account No : <label style={{color:"rgb(148, 10, 228)"}}>510101001850198</label></label>
                    <label>Bank Name : <label style={{color:"rgb(148, 10, 228)"}}>Union Bank Of India</label></label>
                    <label>Branch : <label style={{color:"rgb(148, 10, 228)"}}>Sivakasi</label></label>
                    <label>IFS Code : <label style={{color:"rgb(148, 10, 228)"}}>UBIN0911381</label></label>
                </div>
                <div className="QRCode-inf">
                        <img 
                            src={ QRCodeImg }
                            alt="Description" 
                            style={{ width: '100%', height: '100%',background:"whitesmoke" }} 
                        />
                </div>
            </div>
        </div>
    )
}
export default Contactform;