import React, { useEffect, useState } from 'react';
import "./emptypage.css";
import { myaction } from "../../src/redux/Mystore"
import { useDispatch } from 'react-redux'
import backgroundImage from "../images/background.jpg";
import christmas from "../images/merrychristmas.jpg";
import newyear from "../images/newyear.jpg";


const Emptypage = () => {

  const mydispatch = useDispatch()

  function showmenu() {
    mydispatch(myaction.hideMenu(false))
  }

  useEffect(() => {
    showmenu()
  }, [])
  const [backgroundimage, setBackgroundImage] = useState(backgroundImage);

  useEffect(() => {
    const today = new Date();
    if (today.getMonth() === 11 && today.getDate() === 25) {
      setBackgroundImage(christmas);
    }
    if(today.getMonth() === 0 && today.getDate() === 1){
      setBackgroundImage(newyear);
    }
  }, [])

  return (
    <>
      <div className="mypage"
        style={{
          backgroundImage: `url(${backgroundimage})`, // Use the imported image
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "81vh", // Adjust height as needed
          width: "100%", // Adjust width as needed
        }}
      >

      </div>
    </>
  );
};

export default Emptypage;