import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import "./DeliveryChalan.css";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as u from "../utils/Common";
import 'jspdf-autotable';
import CustomerList from '../GridLookup/CustomerList';
import { AiOutlineClose } from 'react-icons/ai';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { myaction } from '../../redux/Mystore';
import { NumericFormat } from 'react-number-format';
import InputMask from 'react-input-mask';
import ProductMasterList from '../GridLookup/ProductMasterList';
// import CustomerList from '../GridLookup/CustomerList';
import TransportList from '../GridLookup/TransportList';
// import PriceList from '../GridLookup/PriceList';
import FactoryList from '../GridLookup/FactoryList';
// import CompanyList from '../GridLookup/CompanyList';
import { FaSearch } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';

const DeliveryChalan = ({ chalanId }) => {
    let chalanName = '';
    if (chalanId === 1) {
        chalanName = 'Branch Transfer';
    }
    if (chalanId === 2) {
        chalanName = 'Job Based';
    }

    const dccurmode = useSelector(function (data) {
        return data.deliverychalanmode;
    })

    const userid = useSelector(function (data) {
        return data.userid;
    })
    const coyid = useSelector(function (data) {
        return data.coyid;
    })
    console.log(userid)
    console.log(coyid)
    const Navigate = useNavigate();
    const [aggridData, setAggridData] = useState([]);

    const resettopobj = {
        no: '', date: u.curdate(true), bundle: 'Bundle', bundleno: '', transport: '', vehicleno: '', purpose: '', goodsvalue: ''
    }

    const [chalandetails, setChalanDetails] = useState(resettopobj);
    const [selectedtocustomer, setselectedtocustomer] = useState({ actcode: 0, actname: '', add1: '', add2: "", place: '', pincode: '', gstin: '', state: '' });
    const [selectedfromcustomer, setselectedfromcustomer] = useState({ actcode: 0, actname: '', add1: '', add2: "", place: '', pincode: '', gstin: '', state: '' });
    const [selectedtransport, setselectedtransport] = useState({ transportcode: 0, transportname: "" });
    const resetobj = {
        num: '', pdtcode: '', pdtname: '', nofcase: '', packingtype: '', casecontent: '', qty: '', uofm: '', rate: '', rateper: '', amount: ''
    }
    const [chalanItems, setChalanItems] = useState(resetobj)

    useEffect(() => {
        const fetchdata = async (e) => {
            const data = { docuno: dccurmode[1], coyid: coyid, userid: userid };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_read`, data);
            if (response.data.deliverychalan1.length > 0) {
                setChalanDetails({
                    no: response.data.deliverychalan1[0].docuno,
                    date: response.data.deliverychalan1[0].docudate,
                    bundle: response.data.deliverychalan1[0].bundle,
                    bundleno: response.data.deliverychalan1[0].nofbundle,
                    transport: response.data.deliverychalan1[0].transport,
                    vehicleno: response.data.deliverychalan1[0].vehicleno,
                    purpose: response.data.deliverychalan1[0].purpose,
                    goodsvalue: response.data.deliverychalan1[0].goodsvalue,
                })
                setselectedtocustomerobj(0, response.data.deliverychalan1[0].deliveryat, response.data.deliverychalan1[0].deliveryadd1, response.data.deliverychalan1[0].deliveryadd2, response.data.deliverychalan1[0].deliveryplace, response.data.deliverychalan1[0].deliverypincode, response.data.deliverychalan1[0].deliverygstin, response.data.deliverychalan1[0].deliverystate)
                setselectedfromcustomerobj(0, response.data.deliverychalan1[0].despatchfrom, response.data.deliverychalan1[0].despatchadd1, response.data.deliverychalan1[0].despatchadd2, response.data.deliverychalan1[0].despatchplace, response.data.deliverychalan1[0].despatchpincode, response.data.deliverychalan1[0].despatchgstin, response.data.deliverychalan1[0].despatchstate)
                setselectedtransportobj(0, response.data.deliverychalan1[0].transport);
                const dc2data = response.data.deliverychalan2.map((data) => {
                    return {
                        num: data.srl,
                        pdtname: data.pdtname,
                        nofcase: data.nofcase,
                        packingtype: data.packingtype,
                        casecontent: data.casecontent,
                        uofm: data.uofm,
                        qty: data.qty,
                        rate: data.rate,
                        rateper: data.rateper,
                        amount: data.amount,
                    }
                })
                setAggridData(dc2data)
            }
        }
        const fetchlastdocuno = async (e) => {
            const data = {
                coyid: coyid,
                userid: userid
            }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_lastdocuno`, data);
            if (!response.data.docuno) {
                setChalanDetails({ ...chalandetails, no: 1 })
                return;
            }
            setChalanDetails({ ...chalandetails, no: response.data.docuno })
        }
        if (dccurmode[0] === "Edit") {
            fetchdata();
        }
        if (dccurmode[0] === 'New') {
            fetchlastdocuno();
            window.document.getElementById("date").focus();
            window.document.getElementById("date").focus();
        }
    }, [dccurmode]);

    useEffect(() => {
        if (selectedtocustomer.gstin.length > 0) {
            const statecode = selectedtocustomer.gstin.substring(0, 2);
            setselectedtocustomer({ ...selectedtocustomer, state: statecode });
        }
    }, [selectedtocustomer.gstin])

    const handleDetailsChange = (value, name) => {
        setChalanDetails({ ...chalandetails, [name]: value })
    }

    const handleItemsChange = (value, name) => {
        setChalanItems({ ...chalanItems, [name]: value })
    }

    useEffect(() => {
        if (curmode === 'New') {
            const casevalue = isNaN(chalanItems.nofcase) ? 0 : chalanItems.nofcase;
            const casecontentvalue = isNaN(chalanItems.casecontent) ? 0 : chalanItems.casecontent;
            const qtyvalue = casevalue * casecontentvalue;
            setChalanItems({ ...chalanItems, qty: qtyvalue })
        }
    }, [chalanItems.nofcase, chalanItems.casecontent])

    useEffect(() => {
        if (curmode === 'New') {
            const qtyvalue = isNaN(chalanItems.qty) ? 0 : chalanItems.qty;
            const ratevalue = isNaN(chalanItems.rate) ? 0 : chalanItems.rate;
            const ratepervalue = isNaN(chalanItems.rateper) ? 0 : chalanItems.rateper;
            const amountvalue = (qtyvalue / ratepervalue) * ratevalue;
            setChalanItems({ ...chalanItems, amount: amountvalue })
        }
    }, [chalanItems.qty, chalanItems.rate, chalanItems.rateper])

    const [curmode, setcurmode] = useState('');

    const [selectedRow, setSelectedRow] = useState(null);

    const handleEdit = useCallback((row) => {
        console.log(row)
        setTimeout(() => {
            window.document.getElementById("pdtname").select();
            window.document.getElementById("pdtname").focus();
        }, 100);
        setcurmode("Edit");
        setChalanItems(row);
        setSelectedRow(row.num);
        OpenEditModal();
    }, []);

    const handleAdd = () => {
        setcurmode("New");
        setTimeout(() => {
            window.document.getElementById("pdtname").focus();
        }, 200);
        const nextsrl = aggridData.length - 1;
        setChalanItems({ ...resetobj, num: aggridData.length > 0 ? aggridData[nextsrl].num + 1 : 1 });
        setSelectedRow(null);
        OpenEditModal();
    };

    const handleDelete = useCallback((row) => {
        setSelectedRow(row.num);
        Swal.fire({
            title: `Are you sure to Remove the Data of Srl. No ${row.num}?`,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                setAggridData((prevData) => prevData.filter((rows) => rows.num !== row.num));
            }
        });
    }, []);

    const handleSave = () => {
        if (chalanItems.pdtname === '') {
            alert("Item Name Should Be Given");
            window.document.getElementById("pdtname").select();
            window.document.getElementById("pdtname").focus();
            return;
        }
        if (!chalanItems.nofcase > 0) {
            alert("No. of Pack Should Be Given");
            window.document.getElementById("nofcase").select();
            window.document.getElementById("nofcase").focus();
            return;
        }
        if (!chalanItems.nofcase > 0) {
            alert("No. of Pack Should Be Given");
            window.document.getElementById("nofcase").select();
            window.document.getElementById("nofcase").focus();
            return;
        }
        if (curmode === 'New') {
            setAggridData([...aggridData, chalanItems]);
            setChalanItems({ ...resetobj, num: chalanItems.num + 1, pdtname: chalanItems.pdtname, packingtype: chalanItems.packingtype, casecontent: chalanItems.casecontent, uofm: chalanItems.uofm, rate: chalanItems.rate, rateper: chalanItems.rateper });
            window.document.getElementById("pdtname").select();
            window.document.getElementById("pdtname").focus();
        } else {
            const data = aggridData.map(row => (row.num === selectedRow ? chalanItems : row));
            setAggridData(data);
            setChalanItems(resetobj);
            setEditModalOpen(false);
        }
        setSelectedRow(null);
    };

    const [columnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
            }
        },
        { headerName: 'Item Name', headerClass: 'custom-header', field: 'pdtname', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 2 },
        {
            headerName: 'No. of Packages', headerClass: 'custom-header', field: 'nofcase', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6,
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'Packing Type', headerClass: 'custom-header', field: 'packingtype', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6, },
        {
            headerName: 'CPP', headerClass: 'custom-header', field: 'casecontent', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6,
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'Qty', headerClass: 'custom-header', field: 'qty', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5,
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'UOFM', headerClass: 'custom-header', field: 'uofm', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Rate', headerClass: 'custom-header', field: 'rate', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'Rate Per', headerClass: 'custom-header', field: 'rateper', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return String(params.value);
                }
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'Amount', headerClass: 'custom-header', field: 'amount', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'Actions',
            headerClass: 'custom-header',
            field: 'actions',
            cellRenderer: (params) => {
                if (params.node.rowPinned) {
                    return '';
                }
                return <div className="col-action-div action-cell">
                    <button onClick={() => handleEdit(params.data)}>Edit</button>
                    <button onClick={() => handleDelete(params.data)}>Delete</button>
                </div>
            },
            flex: 0.5,
        }
    ]);

    const calculateTotals = () => {
        let totalAmount = 0;

        aggridData.forEach((row) => {
            totalAmount += isNaN(row.amount) ? 0 : row.amount;
        });
        setChalanDetails({ ...chalandetails, goodsvalue: totalAmount })

        return [
            {
                num: String(aggridData.length),
                amount: totalAmount,
                rateper: 'GOODS VALUE'
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        const totals = calculateTotals();
        setPinnedBottomRowData(totals);
    }, [aggridData]);

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
    }

    const [editModalOpen, setEditModalOpen] = useState(false);

    const OpenEditModal = () => {
        setEditModalOpen(true);
        setTimeout(() => {
            window.document.getElementById("pdtname").select();
            window.document.getElementById("pdtname").focus();
        }, 100)
    }

    const onEnterkeydown = (e, nextid) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (nextid === "frmstate" || nextid === "tostate" || nextid === "addnew" || nextid === 'save'
                || nextid === 'bundle' || nextid === 'packingtype' || nextid === 'uofm') {
                window.document.getElementById(nextid).focus();
                return;
            }
            window.document.getElementById(nextid).select();
            window.document.getElementById(nextid).focus();
        }
    }

    const [uofmdata, setuofmdata] = useState([]);
    const [packingtypedata, setpackingtypedata] = useState([]);
    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readstatemaster`)
            setStates(response.data);
        } catch (error) {
            console.error("Error:", error);
        }
    }
    const fetchuofmdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readuofm`)
            setuofmdata(response.data);
            const result = response.data.map((item) =>{
                return {packingtype : item.uofm}
            })
            setpackingtypedata(result);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchuofmdata();
        fetchStates();
    }, []);

    const setselectedtocustomerobj = (actcode, actname, add1, add2, place, pincode, gstin, state) => {
        setselectedtocustomer({ actcode: actcode, actname: actname, add1: add1, add2: add2, place: place, pincode: pincode, gstin: gstin, state: state })
    }
    const setselectedfromcustomerobj = (actcode, actname, add1, add2, place, pincode, gstin, state) => {
        setselectedfromcustomer({ actcode: actcode, actname: actname, add1: add1, add2: add2, place: place, pincode: pincode, gstin: gstin, state: state })
    }
    const setselectedtransportobj = (code, name) => {
        setselectedtransport({ transportcode: code, transportname: name })
    }

    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const [isfromCustomerModalOpen, setIsfromCustomerModalOpen] = useState(false);
    const [isTransportModalOpen, setIsTransportModalOpen] = useState(false);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);

    const onDoubleClickcustomer = () => {
        setIsCustomerModalOpen(true);
    }
    const onDoubleClickfromcustomer = () => {
        setIsfromCustomerModalOpen(true);
    }
    const onDoubleClickTransport = () => {
        setIsTransportModalOpen(true);
    }
    const onDoubleClickItem = () => {
        setIsProductModalOpen(true);
    }
    const closeCustomerModal = () => {
        setIsCustomerModalOpen(false);
    };
    const closefromCustomerModal = () => {
        setIsfromCustomerModalOpen(false);
    };
    const closeTransportModal = () => {
        setIsTransportModalOpen(false);
    };
    const closeProductModal = () => {
        setIsProductModalOpen(false);
    };

    const handleCustomerRowDoubleClick = (data) => {
        const rowData = data;
        setIsCustomerModalOpen(false);
        setselectedtocustomerobj(rowData.actcode, rowData.actname, rowData.add1, rowData.add2, rowData.place, rowData.pincode, rowData.gstin, rowData.statecode);
        window.document.getElementById("toname").select();
        window.document.getElementById("toname").focus();
    };
    const handlefromCustomerRowDoubleClick = (data) => {
        const rowData = data;
        setIsfromCustomerModalOpen(false);
        setselectedfromcustomerobj(rowData.actcode, rowData.actname, rowData.add1, rowData.add2, rowData.place, rowData.pincode, rowData.gstin, rowData.statecode);
        window.document.getElementById("frmname").select();
        window.document.getElementById("frmname").focus();
    };
    const handleTransportRowDoubleClick = (data) => {
        const rowData = data;
        setIsTransportModalOpen(false);
        setselectedtransportobj(rowData.transportcode, rowData.transportname);
        window.document.getElementById("transport").select();
        window.document.getElementById("transport").focus();
    };
    const handleProductRowDoubleClick = (data) => {
        console.log(data)
        const rowData = data;
        setIsProductModalOpen(false);
        setChalanItems({...chalanItems, pdtcode: rowData.pdtcode, pdtname: rowData.pdtname, casecontent: rowData.casecontent, uofm: rowData.uofm, rate: rowData.rate, rateper: rowData.rateper})
        window.document.getElementById("pdtname").select();
        window.document.getElementById("pdtname").focus();
    };

    const handleToCustomerChange = (value, name) => {
        setselectedtocustomer({ ...selectedtocustomer, [name]: value })
    }
    const handlefromCustomerChange = (value, name) => {
        setselectedfromcustomer({ ...selectedfromcustomer, [name]: value })
    }
    const handleTransportChange = (value, name) => {
        setselectedtransport({ ...selectedtransport, [name]: value })
    }

    const handleChalanSave = async () => {
        if (!aggridData.length > 0) {
            alert('Table Contains No Data');
            return;
        }
        let value = chalandetails.date;
        if (!u.validateDate(value)) {
            alert('Please Enter Valid Date');
            window.document.getElementById("date").focus();
            window.document.getElementById("date").select();
            return;
        }

        if (!selectedtocustomer.actname.length > 0) {
            alert("Delivery Place Should be Given");
            window.document.getElementById("toname").focus();
            window.document.getElementById("toname").select();
            return;
        }

        if (!selectedtocustomer.add1.length > 0) {
            alert("Delivery Place Address Should be Given");
            window.document.getElementById("toadd1").focus();
            return;
        }

        if (!selectedtocustomer.place.length > 0) {
            alert("Delivery Place Should be Given");
            window.document.getElementById("toplace").focus();
            return;
        }

        if (!selectedtocustomer.pincode > 0) {
            alert("Delivery Place Should be Given");
            window.document.getElementById("toplace").focus();
            return;
        }

        if (!selectedtocustomer.state > 0) {
            alert("Delivery State Should be Given");
            window.document.getElementById("tostate").focus();
            return;
        }

        if (!selectedtransport.transportname.length > 0) {
            alert("Transport Should be Given");
            window.document.getElementById("transport").focus();
            return;
        }

        const itemlist = aggridData.map(item => {
            return {
                srl: item.num,
                pdtname: item.pdtname,
                nofcase: item.nofcase,
                packingtype: item.packingtype,
                casecontent: item.casecontent,
                qty: item.qty,
                uofm: item.uofm,
                rate: item.rate,
                rateper: item.rateper,
                amount: item.amount
            }
        }
        )
        try {
            const data = {
                userid: userid,
                coyid: coyid,
                trnid: chalanId,
                docuno: chalandetails.no,
                trndate: chalandetails.date,
                deliveryat: selectedtocustomer.actname,
                deliveryadd1: selectedtocustomer.add1,
                deliveryadd2: selectedtocustomer.add2,
                deliveryplace: selectedtocustomer.place,
                deliverypincode: selectedtocustomer.pincode,
                deliverygstin: selectedtocustomer.gstin,
                deliverystate: selectedtocustomer.state,
                despatchfrom: selectedfromcustomer.actname,
                despatchadd1: selectedfromcustomer.add1,
                despatchadd2: selectedfromcustomer.add2,
                despatchplace: selectedfromcustomer.place,
                despatchpincode: selectedfromcustomer.pincode,
                despatchgstin: selectedfromcustomer.gstin,
                despatchstate: selectedfromcustomer.state,
                transport: selectedtransport.transportname,
                vehicleno: chalandetails.vehicleno,
                bundle: chalandetails.bundle,
                nofbundle: chalandetails.bundleno,
                goodsvalue: chalandetails.goodsvalue,
                purpose: chalandetails.purpose,
                itemlist: itemlist,
                doc: u.curdatetime()
            };
            console.log(data);
            let url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_create`
            const res = await axios.post(url, data);
            console.log(res);
            // if (res) {
            //     newdocuno = res.data.docuno;
            // }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        alert("Data Save Successfully....");

        // if (preView) {
        //     const data = { docuno: newdocuno }
        //     const url = `${process.env.REACT_APP_RAINBOW_URL}/packingslip_preview`
        //     const response = await axios.post(url, data, { responseType: 'blob' });
        //     const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        //     window.open(fileURL, '_blank');
        // }

        // preView = false;

        if (dccurmode[0] === "Edit") {
            Navigate(-1);
            return;
        }

        setAggridData([])
        window.document.getElementById("date").select();
        window.document.getElementById("date").focus();
        setselectedtocustomerobj(0, '', '', '', '', '', '', '',);
        setselectedfromcustomerobj(0, '', '', '', '', '', '', '',);
        setselectedtransportobj(0, '');
        setChalanDetails({ ...resettopobj, no: chalandetails.no + 1 });
    };

    return (
        <div className="deliverychalan-divmain">
            <div className='deliverychalan-container'>
                <div className='deliverychalan-header'>
                    <h4>Delivery Challan ({chalanName})</h4>
                    <AiOutlineClose onClick={() => Navigate(-1)} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "16px" }} />
                </div>
                <div className='deliverychalan-top'>
                    <div className='deliverychalan-topleft'>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="no"> Srl.No.</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type="text" id='no' name='no' style={{ width: "20%" }}
                                    value={chalandetails.no} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "date")} readOnly />
                                <label htmlFor="date" style={{ width: "8%" }}>Date</label>
                                <InputMask
                                    id='date'
                                    mask="99/99/99"
                                    autoComplete='off'
                                    placeholder="DD/MM/YY"
                                    maskChar=""
                                    style={{ width: "20%" }}
                                    value={chalandetails.date}
                                    onChange={(e) => handleDetailsChange(e.target.value, e.target.name)}
                                    onKeyDown={(e) => onEnterkeydown(e, "toname")}
                                />
                            </div>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="toname">Delivery at</label>
                            <input type='text' name="actname" id="toname"
                                value={selectedtocustomer.actname}
                                onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                maxLength={100}
                                onDoubleClick={onDoubleClickcustomer}
                                onKeyDown={(event) => {
                                    if (event.key === 'F4') {
                                        onDoubleClickcustomer()
                                    }
                                    if (event.key === 'Enter') {
                                        onEnterkeydown(event, "toadd1")
                                    }
                                }}
                            />
                            {isCustomerModalOpen && (
                                <CustomerList
                                    closeModal={closeCustomerModal}
                                    onRowDoubleClick={handleCustomerRowDoubleClick}
                                    selecteditems={selectedtocustomer}
                                />
                            )}
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="toadd1">Address</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type='text' name="add1" id="toadd1"
                                    value={selectedtocustomer.add1} onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "toadd2")}
                                    maxLength={100} />
                                <input type='text' name="add2" id="toadd2"
                                    value={selectedtocustomer.add2} onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "toplace")} maxLength={100} />
                            </div>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="toplace">Place</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type='text' name="place" id="toplace" maxLength={50} onKeyDown={(e) => onEnterkeydown(e, "topincode")}
                                    style={{ width: "50%" }} value={selectedtocustomer.place} onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))} />
                                <div className='deliverychalan-inputgrp2' style={{ width: "50%" }}>
                                    <label htmlFor="topincode" style={{ width: "22%", textAlign: "left" }}>Pincode</label>
                                    <InputMask
                                        name="pincode" id="topincode" style={{ width: "30%" }}
                                        value={selectedtocustomer.pincode}
                                        onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "togstin")}
                                        mask="999999"
                                        autoComplete='off'
                                        placeholder=""
                                        maskChar=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="togstin">GSTIN</label>
                            <div className='deliverychalan-inputgrp2'>
                                <InputMask
                                    mask="D9AAAAA9999A*Z*"
                                    maskChar={null}
                                    formatChars={{
                                        '*': '[A-Za-z0-9]',
                                        'D': '[0-3]',
                                        'A': '[A-Za-z]',       // Correctly allows alphabetic characters only
                                        'Z': '[Zz]',
                                        '9': '[0-9]'        // Z allows only uppercase alphabets
                                    }}
                                    alwaysShowMask={false}
                                    name="gstin" id="togstin"
                                    style={{ width: "50%", textTransform: "uppercase" }}
                                    value={selectedtocustomer.gstin}
                                    onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "tostate")}
                                ></InputMask>

                                <div className='deliverychalan-inputgrp2' style={{ width: "50%" }}>
                                    <label htmlFor="tostate" style={{ width: "22%", textAlign: "left" }}>State</label>
                                    <select name="state" id="tostate" style={{ width: "78%" }} value={selectedtocustomer.state} onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "frmname")}>
                                        <option value="" disabled selected> Choose</option>
                                        {states.map((item, index) => (
                                            <option key={index} value={item.statecode}>{item.statename}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='deliverychalan-topright'>
                        <div className='deliverychalan-inputgrp' style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                            <button tabIndex={900} onClick={handleChalanSave}>Save</button>
                            <button tabIndex={900}>Print</button>
                            <button tabIndex={900}>Json</button>
                            <button onClick={() => Navigate(-1)} tabIndex={900}>Cancel</button>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="frmname">Desp. From</label>
                            <input type='text' name="actname" id="frmname"
                                value={selectedfromcustomer.actname}
                                onChange={((e) => handlefromCustomerChange(e.target.value, e.target.name))}
                                maxLength={100}
                                onDoubleClick={onDoubleClickfromcustomer}
                                onFocus={() => {
                                    if (chalandetails.toname === 0) { onDoubleClickfromcustomer() }
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'F4') {
                                        onDoubleClickfromcustomer()
                                    }
                                    if (event.key === 'Enter') {
                                        onEnterkeydown(event, "frmadd1")
                                    }
                                }}
                            />
                            {isfromCustomerModalOpen && (
                                <CustomerList
                                    closeModal={closefromCustomerModal}
                                    onRowDoubleClick={handlefromCustomerRowDoubleClick}
                                    selecteditems={selectedfromcustomer}
                                />
                            )}
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="frmadd1">Address</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type='text' name="add1" id="frmadd1" maxLength={100} value={selectedfromcustomer.add1} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "frmadd2")} style={{ width: "50%" }} />
                                <input type='text' name="add2" id="frmadd2" maxLength={100} value={selectedfromcustomer.add2} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "frmplace")} style={{ width: "50%" }} />
                            </div>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="frmplace">Place</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type='text' name="place" id="frmplace" maxLength={50} style={{ width: "50%" }} value={selectedfromcustomer.place} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "frmpincode")} />
                                <div className='deliverychalan-inputgrp2' style={{ width: "50%" }}>
                                    <label htmlFor="frmpincode" style={{ width: "22%", textAlign: "left" }} >Pincode</label>
                                    <InputMask
                                        name="pincode" id="frmpincode" style={{ width: "30%" }}
                                        value={selectedfromcustomer.pincode}
                                        onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "frmgstin")}
                                        mask="999999"
                                        autoComplete='off'
                                        placeholder=""
                                        maskChar=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="frmgstin">GSTIN</label>
                            <div className='deliverychalan-inputgrp2'>
                                <InputMask
                                    mask="D9AAAAA9999A*Z*"
                                    maskChar={null}
                                    formatChars={{
                                        '*': '[A-Za-z0-9]',
                                        'D': '[0-3]',
                                        'A': '[A-Za-z]',       // Correctly allows alphabetic characters only
                                        'Z': '[Zz]',
                                        '9': '[0-9]'        // Z allows only uppercase alphabets
                                    }}
                                    alwaysShowMask={false}
                                    name="gstin" id="frmgstin"
                                    style={{ width: "50%", textTransform: "uppercase" }}
                                    value={selectedfromcustomer.gstin}
                                    onChange={((e) => handleToCustomerChange(e.target.value, e.target.name))}
                                    onKeyDown={(e) => onEnterkeydown(e, "frmstate")}
                                ></InputMask>

                                <div className='deliverychalan-inputgrp2' style={{ width: "50%" }}>
                                    <label htmlFor="frmstate" style={{ width: "22%", textAlign: "left" }} >State</label>
                                    <select type='text' name="state" id="frmstate" style={{ width: "78%" }}
                                        value={selectedfromcustomer.state} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "transport")} >
                                        <option value="" disabled selected> Choose</option>
                                        {states.map((item, index) => (
                                            <option key={index} value={item.statecode}>{item.statename}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='deliverychalan-top2'>
                    <div className='deliverychalan-topleft' style={{ width: "50%" }}>
                        <div className='deliverychalan-inputgrp'>
                            <label htmlFor="transport"> Transport</label>
                            <div className='deliverychalan-inputgrp2'>
                                <input type='text' id='transport' name='transportname'
                                    style={{ width: "50%" }}
                                    value={selectedtransport.transportname}
                                    maxLength={100}
                                    onChange={((e) => handleTransportChange(e.target.value, e.target.name))}
                                    onDoubleClick={onDoubleClickTransport}
                                    onFocus={() => {
                                        if (chalandetails.toname === 0) { onDoubleClickTransport() }
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'F4') {
                                            onDoubleClickTransport()
                                        }
                                        if (event.key === 'Enter') {
                                            onEnterkeydown(event, "vehicleno")
                                        }
                                    }}
                                />
                                {isTransportModalOpen && (
                                    <TransportList
                                        closeModal={closeTransportModal}
                                        onRowDoubleClick={handleTransportRowDoubleClick}
                                        selecteditems={selectedtransport}
                                    />
                                )}
                                <div className='deliverychalan-inputgrp2' style={{ width: "50%" }}>
                                    <label htmlFor="vehicleno" style={{ width: "22%" }}> Veh. No.</label>
                                    <input type="text" id='vehicleno' maxLength={12} name='vehicleno' value={chalandetails.vehicleno} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "bundle")} style={{ width: "78%", textTransform: "uppercase" }} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='deliverychalan-topleft' style={{ width: "50%" }}>
                        <div className='deliverychalan-inputgrp'>
                            <select name="bundle" id="bundle" style={{ width: "15%" }} value={chalandetails.bundle} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                onKeyDown={(e) => onEnterkeydown(e, "bundleno")}>
                                <option value="" disabled selected> Choose</option>
                                {uofmdata.map((item, index) => (
                                    <option key={index} value={item.uofm}>{item.uofm}</option>
                                ))}
                            </select> &nbsp;

                            <div className='deliverychalan-inputgrp2'>
                                <NumericFormat
                                    id='bundleno' name='bundleno' maxLength={5}
                                    autoComplete='off'
                                    className='numericformatinput'
                                    decimalScale={3}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    isNumericString
                                    value={chalandetails.bundleno}
                                    onValueChange={(values) => handleDetailsChange(values.floatValue, 'bundleno')}
                                    style={{ width: "10%" }}
                                    onKeyDown={(e) => onEnterkeydown(e, "purpose")}
                                />
                                <div className='deliverychalan-inputgrp2' style={{ width: "90%" }}>
                                    <label htmlFor="purpose" style={{ width: "20%", textAlign: 'center' }}> Purpose</label>
                                    <input type="text" id='purpose' name='purpose' value={chalandetails.purpose} onChange={((e) => handleDetailsChange(e.target.value, e.target.name))}
                                        onKeyDown={(e) => onEnterkeydown(e, "addnew")} style={{ width: "82%" }} maxLength={100} />
                                    <button id='addnew' onClick={handleAdd}><FaPlus /></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='deliverychalan-aggrid'>
                    <div className="aag-theme-alpine" style={{ width: "99%", height: "98%" }}>
                        <AgGridReact
                            rowHeight={20}
                            rowData={aggridData}
                            columnDefs={columnDefs}
                            pinnedBottomRowData={pinnedBottomRowData}
                            domLayout="normal"
                            defaultColDef={{ resizable: true, flex: 1 }}
                            getRowStyle={getRowStyle}
                        />
                    </div>
                </div>
            </div>
            {editModalOpen &&
                <div className='deliverychalan-editmodal'>
                    <div className='deliverychalan-editmodal-container'>
                        <button className="deliverychalan-editmodal-button" onClick={() => setEditModalOpen(false)}>
                            &times;
                        </button>
                        <div className='editfields-inputgrp ' style={{ width: "6%" }}>
                            <label htmlFor="srl">Srl.</label>
                            <NumericFormat
                                readOnly={true}
                                disabled={true}
                                tabIndex={-1}
                                id='srl'
                                name='num'
                                autoComplete='off'
                                className='numericformatinput'
                                thousandSeparator={false}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                isNumericString
                                value={chalanItems.num}
                                style={{ width: "100%" }}
                                onKeyDown={(e) => onEnterkeydown(e, "pdtname")}
                            />
                        </div>
                        <div className='editfields-inputgrp' style={{ width: "20%" }}>
                            <label htmlFor="pdtname">Item Name</label>
                            <input type="text" id='pdtname' name='pdtname'
                                value={chalanItems.pdtname}
                                maxLength={150}
                                onChange={(e) => handleItemsChange(e.target.value, e.target.name)}
                                style={{ width: "100%" }} onDoubleClick={onDoubleClickItem}
                                onKeyDown={(event) => {
                                    if (event.key === 'F4') {
                                        onDoubleClickItem()
                                    }
                                    if (event.key === 'Enter') {
                                        onEnterkeydown(event, "nofcase")
                                    }
                                }}
                            />
                            {isProductModalOpen && (
                                <ProductMasterList
                                    closeModal={closeProductModal}
                                    onRowDoubleClick={handleProductRowDoubleClick}
                                    selecteditems={chalanItems}
                                />
                            )}
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "8%" }}>
                            <label htmlFor="nofcase">No.of Pkg.</label>
                            <NumericFormat
                                id='nofcase'
                                name='nofcase'
                                autoComplete='off'
                                className='numericformatinput'
                                decimalScale={0}
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                value={chalanItems.nofcase}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'nofcase')}
                                style={{ width: "100%" }}
                                maxLength={15}
                                onKeyDown={(e) => onEnterkeydown(e, "packingtype")}
                            />
                        </div>
                        <div className='editfields-inputgrp' style={{ width: "8%" }}>
                            <label htmlFor="packingtype"> Pkg.Type</label>
                            <select id='packingtype' name='packingtype'
                                style={{ width: "100%" }}
                                value={chalanItems.packingtype}
                                onChange={(e) => handleItemsChange(e.target.value, e.target.name)}
                                onKeyDown={(e) => onEnterkeydown(e, "casecontent")}>
                                <option value="" disabled selected> Choose</option>
                                {packingtypedata.map((item, index) => (
                                    <option key={index} value={item.packingtype}>{item.packingtype}</option>
                                ))}
                            </select>
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "8%" }}>
                            <label htmlFor="casecontent">CPP.</label>
                            <NumericFormat
                                id='casecontent'
                                name='casecontent'
                                autoComplete='off'
                                className='numericformatinput'
                                decimalScale={0}
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                maxLength={15}
                                value={chalanItems.casecontent}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'casecontent')}
                                style={{ width: "100%" }}
                                onKeyDown={(e) => onEnterkeydown(e, "qty")}
                            />
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "12%" }}>
                            <label htmlFor="qty">Qty.</label>
                            <NumericFormat
                                id='qty'
                                name='qty'
                                autoComplete='off'
                                className='numericformatinput'
                                decimalScale={2}
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                maxLength={15}
                                value={chalanItems.qty}
                                // readOnly={chalanItems.casecontent > 0 || !pscurmode}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'qty')}
                                style={{ width: "100%" }}
                                onKeyDown={(e) => onEnterkeydown(e, "uofm")}
                            />
                        </div>
                        <div className='editfields-inputgrp' style={{ width: "8%" }}>
                            <label htmlFor="uofm">UOFM</label>
                            <select id='uofm' name='uofm'
                                style={{ width: "100%" }}
                                value={chalanItems.uofm}
                                onChange={(e) => handleItemsChange(e.target.value, e.target.name)}
                                onKeyDown={(e) => onEnterkeydown(e, "rate")}>
                                <option value="" disabled selected> Choose</option>
                                {uofmdata.map((item, index) => (
                                    <option key={index} value={item.uofm}>{item.uofm}</option>
                                ))}
                            </select>
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "10%" }}>
                            <label htmlFor="rate">Rate</label>
                            <NumericFormat
                                id='rate'
                                name='rate'
                                autoComplete='off'
                                className='numericformatinput'
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                decimalScale={2}
                                value={chalanItems.rate}
                                maxLength={15}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'rate')}
                                style={{ width: "100%" }}
                                onKeyDown={(e) => onEnterkeydown(e, "rateper")}
                            />
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "8%" }}>
                            <label htmlFor="rateper">Rate Per</label>
                            <NumericFormat
                                id='rateper'
                                name='rateper'
                                autoComplete='off'
                                className='numericformatinput'
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                value={chalanItems.rateper}
                                decimalScale={2}
                                maxLength={15}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'rateper')}
                                style={{ width: "100%" }}
                                onKeyDown={(e) => onEnterkeydown(e, "amount")}
                            />
                        </div>
                        <div className='editfields-inputgrp numericformatinput' style={{ width: "10%" }}>
                            <label htmlFor="amount">Amount</label>
                            <NumericFormat
                                id='amount'
                                name='amount'
                                autoComplete='off'
                                className='numericformatinput'
                                thousandSeparator={false}
                                allowNegative={true}
                                allowLeadingZeros={false}
                                isNumericString
                                decimalScale={2}
                                value={chalanItems.amount}
                                onValueChange={(values) => handleItemsChange(values.floatValue, 'amount')}
                                style={{ width: "100%" }}
                                maxLength={15}
                                onKeyDown={(e) => onEnterkeydown(e, "save")}
                            />
                        </div>
                        <div className='editfields-btngrp' style={{ width: "8%" }}>
                            <button id='save' className="ok-btn" onClick={handleSave} >Save</button>
                            <button onClick={() => setEditModalOpen(false)} className="cancel-btn" >Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div >
    );

};
export default DeliveryChalan;
