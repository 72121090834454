import React, { useState, useEffect, useRef } from 'react';
import './GSTR1View.css';
import InputMask from 'react-input-mask';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import JSZip from 'jszip';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";

const AgeGridForm = () => {

    const [videoUrl, setCurrentValue] = useState('');
    const configArray = [{ id_name: 'GSTR9-Youtube URL', id_value: videoUrl }];
    const fetchConfigValue = async () => {
        try {
            const data = { configArray }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
            setCurrentValue(response.data[0].id_value);
        } catch (error) {
            console.error('Error fetching config value:', error);
        }
    };


    const [statesData, setStatesData] = useState([]);
    const fetchStatedata = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/api/states`);
            setStatesData(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchConfigValue();
        fetchStatedata();
    }, []);
    let toolname = 'GSTR1 View';

    const navigate = useNavigate();

    const gridRef = useRef();
    const [gstin, setgstin] = useState('');
    const jsonInputRef = useRef();
    let dataobj = ['b2b', 'b2ba', 'cdnr', 'cdnra', 'b2cs', 'b2csa', 'hsn'];

    const [existingData, setExistingData] = useState([]);
    const [typeofdata, setTypeofData] = useState('hsn');
    const [filePrd, setFilePrd] = useState('');

    const [hsncolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
        },
        { headerName: 'HSN/SAC', headerClass: 'custom-header', field: 'hsn_sc', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.9 },
        {
            headerName: 'Qty', headerClass: 'custom-header', field: 'qty', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.9,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'UQC', headerClass: 'custom-header', field: 'uqc', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.3, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },

    ]);

    const [b2bcdnrcolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,

        },
        { headerName: 'CTIN', headerClass: 'custom-header', field: 'ctin', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        {
            headerName: 'Bill No', headerClass: 'custom-header', field: 'inum', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
            }
        },
        { headerName: 'Date', headerClass: 'custom-header', field: 'idt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8 },
        { headerName: 'Inv. Type', headerClass: 'custom-header', field: 'inv_typ', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'Source', headerClass: 'custom-header', field: 'source', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.7 },
    ]);
    const [b2ccolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
        },
        { headerName: 'State', headerClass: 'custom-header', field: 'statename', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.5 },

        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.3, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
    ]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };

    const [jsonData, setJsonData] = useState({});
    const handleImportHSN = async (event) => {
        const files = event.target.files[0];
        const fileName = files.name.toLowerCase();
        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                setJsonData(parsedData);
                if (parsedData?.fp) {
                    const date = parsedData.fp;
                    const fp = date.substring(0, 2) + " / " + date.substring(2);
                    setFilePrd(fp);
                }

                if (parsedData?.gstin) {
                    setgstin(parsedData.gstin);
                }
                console.log(parsedData);
                pushData(parsedData);
            }

        } catch (error) {
            console.error("Error processing file:", error);
            return;
        }
        jsonInputRef.current.value = '';
    };
    const isEmpty = (data) => Object.keys(data).length === 0;
    const pushData = (Data) => {
        if (isEmpty(Data)) {
            return;
        }
        const parsedData = Data;
        let mergedData = [];
        if (typeofdata === 'hsn') {
            if (!parsedData.hsn) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                mergedData.push(...parsedData.hsn.data);
            }

        }
        if (typeofdata === 'b2b' || typeofdata === 'b2ba') {
            const data = typeofdata === 'b2b' ? parsedData.b2b : parsedData.b2ba;
            if (!data) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                data.forEach((b2bItem) => {
                    b2bItem.inv.forEach((invoice) => {
                        invoice.itms.forEach((item) => {
                            const mergeData = { ...item.itm_det, ctin: b2bItem.ctin, inum: invoice.inum, idt: invoice.idt, inv_typ: invoice.inv_typ, source: invoice.srctyp ? invoice.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });
                });
            }
        }
        if (typeofdata === 'cdnr' || typeofdata === 'cdnra') {
            const data = typeofdata === 'cdnr' ? parsedData.cdnr : parsedData.cdnra;
            if (!data) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                data.forEach((cdnrItem) => {
                    cdnrItem.nt.forEach((nt) => {
                        nt.itms.forEach((item) => {
                            const mergeData = { ...item.itm_det, ctin: cdnrItem.ctin, inum: nt.nt_num, idt: nt.nt_dt, inv_typ: nt.ntty, source: nt.srctyp ? nt.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });
                });
            }
        }
        if (typeofdata === 'b2cs' || typeofdata === 'b2csa') {
            const data = typeofdata === 'b2cs' ? parsedData.b2cs : parsedData.b2csa;
            if (!data) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                const result = data.map((row) => {
                    const statename = statesData.find(states => states["gstcode"] === row.pos);
                    if (statename) {
                        return { ...row, statename: statename.name + " - " + row.pos }
                    }
                    else {
                        return { ...row }
                    }
                })
                mergedData.push(...result);
            }
        }
        const updateddata = addNum(mergedData);
        setExistingData(updateddata);

    }

    useEffect(() => {
        pushData(jsonData);
    }, [typeofdata])

    const handleDataChange = (event) => {
        const value = event.target.value;
        setTypeofData(value);
    }

    const calculateTotals = () => {
        let totalTaxableValue = 0, totalIGST = 0, totalCGST = 0, totalSGST = 0, totalCESS = 0;

        existingData.forEach((row) => {
            totalTaxableValue += isNaN(row.txval) ? 0 : row.txval;
            totalIGST += isNaN(row.iamt) ? 0 : row.iamt;
            totalCGST += isNaN(row.camt) ? 0 : row.camt;
            totalSGST += isNaN(row.samt) ? 0 : row.samt;
            totalCESS += isNaN(row.csamt) ? 0 : row.csamt;
        });

        return [
            {
                hsn_sc: 'Total-->',
                ctin: 'Total-->',
                qty: String(existingData.length),
                inum: String(existingData.length),
                statename: 'Total-->' + " " + "(" + String(existingData.length) + ")",
                txval: totalTaxableValue,
                iamt: totalIGST,
                camt: totalCGST,
                samt: totalSGST,
                csamt: totalCESS,
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        const totals = calculateTotals();
        setPinnedBottomRowData(totals);
    }, [existingData]);

    const readJsonFile = async (file) => {
        try {
            const content = await file.text();
            const jsonData = JSON.parse(content);
            return jsonData;
        } catch (error) {
            console.error("Error reading JSON file:", error);
            throw error;
        }
    };
    const readJsonFromZip = async (file) => {
        try {
            const zip = await JSZip.loadAsync(file);
            // Create an array of promises to handle each file in the ZIP
            const jsonPromises = Object.keys(zip.files).map(async (relativePath) => {
                const zipEntry = zip.files[relativePath];

                if (zipEntry.name.endsWith(".json")) {
                    const content = await zipEntry.async("string"); // Read the JSON file as text
                    const jsonData = JSON.parse(content); // Parse the content into JSON
                    return jsonData;
                }
            });

            // Wait for all promises to resolve and filter out undefined results
            const jsonFiles = (await Promise.all(jsonPromises)).filter(Boolean);

            if (jsonFiles.length === 0) {
                throw new Error("No JSON files found in the ZIP");
            }

            return jsonFiles.length === 1 ? jsonFiles[0] : jsonFiles;
        } catch (error) {
            console.error("Error reading ZIP file:", error);
            throw error;
        }
    };

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
    };

    return (
        <div className='gstr1view-divmain'>
            <div className="gstr1view-formcontainer">
                <div className='gstr1view-header'>
                    <h6>{toolname}</h6>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaYoutube style={{ color: "#ff0000", fontSize: "28px", marginRight: "2px" }} onClick={() => window.open(videoUrl, "_blank", "noopener, noreferrer")} />
                        <AiOutlineClose onClick={() => navigate("/emptypage")} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "18px" }} />
                    </div>
                </div>
                <div className='gstr1view-toppanel'>
                    <div className="input-group">
                        <select id='typeofdata'
                            name='typeofdata'
                            onChange={handleDataChange}
                            value={typeofdata}
                            style={{ width: "40%" }}>
                            <option value='' disabled selected>Choose</option>
                            {dataobj.map(data => (
                                <option key={data}
                                    value={data}>{data}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='file-upload-div'>
                        <label htmlFor="file-upload" style={{ paddingTop: "3px" }} className="file-upload-button" >Imp.GSTR1 JSON</label>
                        <input type="file" accept=".json, .zip" multiple={false} id="file-upload"
                            onChange={handleImportHSN} style={{ width: "0", height: "22px", display: "none" }}
                            ref={jsonInputRef} />
                    </div>

                    <div className="input-group">
                        <label>Period:</label>
                        <input type='text' id='fp' value={filePrd}
                            style={{ width: "35%" }}
                            readOnly>

                        </input>
                    </div>
                    <div className="input-group">
                        <label>GSTIN:</label>
                        <InputMask
                            autoComplete='off'
                            mask="D9AAAAA9999A*Z*"
                            maskChar={null}
                            formatChars={{
                                '*': '[A-Za-z0-9]',
                                'D': '[0-3]',
                                'A': '[A-Za-z]',
                                'Z': '[Zz]',
                                '9': '[0-9]'
                            }}
                            id='gstin'
                            alwaysShowMask={false}
                            value={gstin} onChange={(e) => setgstin(e.target.value)}
                            readOnly
                        ></InputMask>
                    </div>


                </div>
                <div className='gstr1view-container'>
                    <div className="gstr1view-agegrid ">
                        <div className="aag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                rowHeight={25}
                                rowData={existingData}
                                columnDefs={
                                    typeofdata === 'b2b' || typeofdata === 'b2ba' ||
                                        typeofdata === 'cdnr' || typeofdata === 'cdnra' ? b2bcdnrcolumnDefs :
                                        typeofdata === 'b2cs' || typeofdata === 'b2csa' ||
                                            typeofdata === 'b2cl' || typeofdata === 'b2cla' ? b2ccolumnDefs : hsncolumnDefs}
                                domLayout="normal"
                                defaultColDef={{ resizable: true, flex: 1 }}
                                pinnedBottomRowData={pinnedBottomRowData}
                                getRowStyle={getRowStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgeGridForm;
