import React, { useState } from "react";
import "./Navbar.css";
import { FaHome, FaUpload, FaWhatsapp, FaCog, FaPhone, FaTruck, FaBuilding } from "react-icons/fa";
import { Link, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { myaction } from "../../redux/Mystore";

const Navbar = () => {

  const [whatsapp, setWhatsapp] = useState(false);
  const [challan, setChallan] = useState(false);

  let hideMenu = useSelector((xdata) => {
    return xdata.hidemenu;
  })

  const isadmin = useSelector((xdata) => {
    return xdata.isAdmin;
  })

  const username = useSelector(function (data) {
    return data.gstr9username;
  })
  const UserName = username.toUpperCase();

  const mydispatch = useDispatch()

  function sethidemenu(params) {
    mydispatch(myaction.hideMenu(true))
  }

  function openwhatsappmodel(params) {
    setWhatsapp(!whatsapp)
  }

  function openchallanmodel(params) {
    setChallan(!challan)
  }

  function hidedata(params) {
    setWhatsapp(false)
    setChallan(false)
  }

  return (
    <>
      <div class={hideMenu ? "navbar-hide" : "navbar"}>
        <ul class="navbar-item hide">

          <li class="active"><FaHome />&nbsp; Home</li>


          <Link to="/annualreturn" className="linkcom" onClick={sethidemenu}>
            <li>GSTR9 HSN</li>
          </Link>

          <Link to="/gstr1hsn" className="linkcom" onClick={sethidemenu}>
            <li>GSTR1 HSN</li>
          </Link>

          <Link to="/gstr2b" className="linkcom" onClick={sethidemenu}>
            <li> GSTR2B</li>
          </Link>

          {isadmin &&
            <Link to="/gstr1view" className="linkcom" onClick={sethidemenu}>
              <li> GSTR1 View</li>
            </Link>
          }
          {isadmin &&
            <li className="dropdown" onClick={openchallanmodel} onMouseLeave={hidedata}><FaTruck />&nbsp; Delivery Challan
              {challan && (<ul className="droplist1">
                <Link to="/listdeliverychalan-branchtransfer" className="linkcom1" onClick={sethidemenu}>
                  <li>Branch Tranfer</li>
                </Link>
                <Link to="/listdeliverychalan-jobbased" className="linkcom1" onClick={sethidemenu}>
                  <li>Job Based</li>
                </Link>
              </ul>)}
            </li>

          }
          {isadmin &&
            <Link to="/license" className="linkcom" onClick={sethidemenu}>
              <li> Activation</li>
            </Link>
          }
          {isadmin &&
            <Link to="/viewusers" className="linkcom" onClick={sethidemenu}>
              <li> Users List</li>
            </Link>
          }
          {isadmin &&
            <Link to="/flashnews" className="linkcom" onClick={sethidemenu}>
              <li><FaCog />&nbsp; Settings</li>
            </Link>
          }
          {(isadmin || UserName === 'RSDATA') &&
            <li className="dropdown" onClick={openwhatsappmodel} onMouseLeave={hidedata}><FaWhatsapp />&nbsp; Whatsapp
              {whatsapp && (<ul className="droplist1">
                <Link to="/uploadpdf" className="linkcom1" onClick={sethidemenu}>
                  <li><FaUpload />&nbsp; Upload PDF</li>
                </Link>
                <Link to="/smssettings" className="linkcom1" onClick={sethidemenu}><li><FaCog />&nbsp; Settings</li></Link>
              </ul>)}
            </li>
          }
          {isadmin &&
            <Link to="/company" className="linkcom" onClick={sethidemenu}>
              <li> <FaBuilding />&nbsp; Company</li>
            </Link>
          }

          <Link to="/contactus" className="linkcom" onClick={sethidemenu}>
            <li> <FaPhone />&nbsp; Contact Us</li>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
