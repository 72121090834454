import { Routes,Route } from "react-router-dom"
import React from "react";
import MyLoginPage from "../pages/MyLoginPage";
import Signin from "../components/Users/Signin";
import Login from "../components/Users/Login";
import Emptypage from "../pages/Emptypage";
import Flashnews from "../components/flashNews/Flashnews";
import License from "../components/License/License";
import Company from "../components/Company/Company";
import Contactus from "../components/ContactUs/ContactUs";
import AnnualReturn from "../components/AnnualReturn/AnnualReturn";
import GSTR1View from "../components/GSTR/GSTR1View";
import GSTR2B from "../components/GSTR/GSTR2B";
import UploadPdf from "../components/Whatsapp/Uploadpdf";
import SMSSettings from "../components/Whatsapp/SMSSettings";
import UsersList from "../components/UsersList/UsersList";
import ChangePassword from "../components/Users/ChangePassword";
import DeliveryChalanLookup from "../components/DeliveryChalan/DeliveryChalanLookup";
import DeliveryChalan from "../components/DeliveryChalan/DeliveryChalan";
const Routers = () => {
  return (
    <>
    
    <Routes>
        <Route path="/" element={<MyLoginPage/>}/>
        <Route path="/login" element={ <Login/>}/>
        <Route path="/signin" element={ <Signin/>}/>
        <Route path="/forgotpassword" element={ <Signin formmode={'FP'}/>}/>
        <Route path="/changepassword" element={ <ChangePassword/>}/>
        <Route path="/emptypage" element={ <Emptypage/>}/>

        <Route path="/flashnews" element={  <Flashnews/>}/>
        <Route path="/license" element={ <License/>}/>
        <Route path="/viewusers" element={ <UsersList/>}/>
        <Route path="/annualreturn" element={ <AnnualReturn/>}/>   
        <Route path="/gstr1hsn" element={ <AnnualReturn formmode={'GSTR1HSN'}/>}/>  
        <Route path="/gstr1view" element={ <GSTR1View/>}/>  
        <Route path="/gstr2b" element={ <GSTR2B/>}/>  
        <Route path="/uploadpdf" element={ <UploadPdf/>}/>   
        <Route path="/smssettings" element={ <SMSSettings/>}/>   
        <Route path="/company" element={ <Company/>}/>   
        <Route path="/contactus" element={ <Contactus/>}/>   

        <Route path="/listdeliverychalan-branchtransfer" element={ <DeliveryChalanLookup chalanId={1} />}/>
        <Route path="/listdeliverychalan-jobbased" element={ <DeliveryChalanLookup chalanId={2} />}/>
        <Route path="/installdeliverychalan-branchtransfer" element={ <DeliveryChalan chalanId={1} />}/>
        <Route path="/installdeliverychalan-jobbased" element={ <DeliveryChalan chalanId={2} />}/>
    </Routes>
        
    </>
  );
};

export default Routers;
