import React, { useState, useEffect, useCallback } from 'react';
import "./Company.css";
import CompanyList from '../GridLookup/CompanyList';
import {  useDispatch } from 'react-redux'
import { myaction } from "../../redux/Mystore"
import { useNavigate } from 'react-router-dom';
const Company = () => {
    const navigate = useNavigate();    
  const mydispatch = useDispatch();
    const [selectedcompany, setselectedcompany] = useState({ actcode: 0, actname: '' });
    const handleCompanyChange = (value, name) =>{
        setselectedcompany({...selectedcompany, [name]: value});
    }
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const closeCompanyModal = () => {
        setIsCompanyModalOpen(false);
    };
    const handleCompanyRowDoubleClick = (data) => {
        const rowData = data;
        setIsCompanyModalOpen(false);
        setselectedcompany({ actcode: rowData.actcode, actname: rowData.actname});
        window.document.getElementById("company").focus();
    };
    const handleSave = () =>{
        if(selectedcompany.actname === ''){
            alert("Company Name Shoukd Be Given");
            window.document.getElementById("company").focus();
            return;
        }
        console.log(selectedcompany.actcode)
        mydispatch(myaction.mycoyid(selectedcompany.actcode));
        alert("Saved Successfully");
    }
    return (
        <div className='company-divmain'>
            <div className='company-container'>
                <div className='company-inputgrp'>
                    <label htmlFor="company">Company:</label>
                    <input type="text" id='company' name='company' placeholder='Company Name' value={selectedcompany.actname}
                                    onChange={((e) => handleCompanyChange(e.target.value, e.target.name))}
                                    onDoubleClick={() => setIsCompanyModalOpen(true)}
                                    onKeyDown={(event) => {
                                        if (event.key === 'F4') {
                                            setIsCompanyModalOpen(true);                        
                                        }
                                        if (event.key === 'Enter') {
                                            window.document.getElementById("save").focus();
                                        }
                                    }}
                                />
                                {isCompanyModalOpen && (
                                    <CompanyList
                                        closeModal={closeCompanyModal}
                                        onRowDoubleClick={handleCompanyRowDoubleClick}
                                        selecteditems={selectedcompany}
                                    />
                                )}
                </div>
                <div className='company-btngrp'>
                    <button id='save' onClick={handleSave}>Save</button>
                    <button onClick={() => navigate(-1)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
export default Company;